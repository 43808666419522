<template>
  <v-card outlined>
    <v-card-text class="mt-4">
      <v-row>
        <v-col cols="12" sm="6" class="py-0">
          <vx-input
            v-model="page.name"
            type="text"
            name="name"
            label="Name"
            required
            @change="page.setSlug(page.slug || page.name)"
          />
        </v-col>

        <v-col cols="12" sm="6" class="py-0">
          <vx-input
            v-model="page.slug"
            type="text"
            name="slug"
            label="Slug"
            required
            :disabled="$route.params.id && !dev"
            @change="page.setSlug(page.slug)"
          />
        </v-col>

        <v-col cols="12" sm="6" class="py-0">
          <vx-input
            v-model="page.description"
            type="textarea"
            name="description"
            label="Description"
            counter="280"
            max="280"
          />
        </v-col>

        <v-col cols="12" sm="6" class="py-0">
          <vx-input v-model="page.path" type="text" name="path" label="Path" required />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    page: { type: Object, required: true },
  },
};
</script>
