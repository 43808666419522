<template>
  <div>
    <v-draggable :list="page.components" handle=".handle" ghost-class="ghost">
      <v-card v-for="(component, index) in page.components" :key="key + index" class="mb-4" outlined>
        <v-card-title class="py-2">
          <vx-btn icon class="handle">
            <vx-icon>mdi-drag</vx-icon>
          </vx-btn>

          #{{ index + 1 }}

          <v-spacer />

          <vx-btn icon :disabled="index === 0" @click="up(index)">
            <vx-icon>mdi-arrow-up</vx-icon>
          </vx-btn>

          <vx-btn icon :disabled="index === page.components.length - 1" @click="down(index)">
            <vx-icon>mdi-arrow-down</vx-icon>
          </vx-btn>

          <vx-btn icon :disabled="page.components.length == 1" @click="removeField(index)">
            <vx-icon>mdi-delete</vx-icon>
          </vx-btn>
        </v-card-title>

        <v-card-text class="my-4">
          <v-row>
            <v-col cols="12" sm="6" class="py-0">
              <vx-input
                v-model="component._schemaId"
                type="autocomplete"
                :items="components"
                item-text="name"
                item-value="_id"
                name="name"
                label="Component"
                required
                append-outer-icon="mdi-pencil"
                @click:append-outer="$edit('component-schema', component._schemaId)"
              />

              <vx-input v-model="component.name" type="text" name="name" label="Name (override)" />
              <vx-input
                v-model="component.class"
                type="text"
                name="class"
                label="Class (css)"
                append-outer-icon="mdi-information-outline"
                @click:append-outer="$openBlankWindow({ href: 'https://vuetifyjs.com/en/styles/spacing' })"
              />

              <vx-input v-model="component.isActive" type="checkbox" name="isActive" label="Enabled?" />
            </v-col>

            <v-col cols="12" sm="6" class="py-0">
              <vx-img class="rounded border-primary-1" rectangle :src="componentsObject[component._schemaId]?.image" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-draggable>

    <v-card>
      <vx-btn block x-large color="primary" @click="dialog = true">Add component</vx-btn>
    </v-card>

    <vx-dialog v-model="dialog" width="1200">
      <template slot="title">
        <vx-input v-model="search" class="w-50" hide-details type="text" name="name" label="Search component" />
      </template>

      <v-row>
        <template v-for="(component, index) in components">
          <v-col
            v-show="component.name.toLowerCase().includes((search || '').toLowerCase())"
            :key="index"
            cols="12"
            sm="4"
          >
            <v-card outlined class="h-100" @click="addComponent(component)">
              <vx-img rectangle :src="component.image" />
              <v-divider />
              <v-card-subtitle>{{ component.name }}</v-card-subtitle>
            </v-card>
          </v-col>
        </template>
      </v-row>
    </vx-dialog>
  </div>
</template>

<script>
import vDraggable from 'vuedraggable';

export default {
  name: 'PageSchemaComponents',

  components: { vDraggable },

  props: {
    page: { type: Object, required: true },
    components: { type: Array, required: true },
    componentsObject: { type: Object, required: true },
  },
  data() {
    return {
      key: 0,
      search: '',
      dialog: false,
    };
  },
  methods: {
    addComponent(component) {
      this.page.addComponent(component);
      this.key++;
      this.dialog = false;
    },
    removeField(index) {
      this.page.removeField(index);
      this.key++;
    },
    up(index) {
      const [component] = this.page.components.splice(index, 1);
      this.page.components.splice(index - 1, 0, component);
      this.key++;
    },
    down(index) {
      const [component] = this.page.components.splice(index, 1);
      this.page.components.splice(index + 1, 0, component);
      this.key++;
    },
  },
};
</script>
