<template>
  <v-form ref="form" lazy-validation>
    <v-card v-if="created">
      <v-card-title class="card-title-actions">
        <v-spacer />
        <vx-btn width="100" outlined color="light" class="ml-2" @click="$previous()">Cancel</vx-btn>
        <vx-btn width="100" outlined color="light" class="ml-2" :loading="loading" @click="$validate(save)">
          Save
        </vx-btn>
        <vx-btn outlined color="light" class="ml-2" :loading="loading" @click="$validate(save, true)">
          Save & close
        </vx-btn>
      </v-card-title>

      <v-card-text class="pt-4">
        <v-tabs v-model="tab" :show-arrows="$vuetify.breakpoint.xs">
          <v-tab>Basic</v-tab>
          <v-tab>Components</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <Basic :page="page" />
          </v-tab-item>

          <v-tab-item>
            <Components :page="page" :components="components" :components-object="componentsObject" />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import { PageSchemaService, ComponentSchemaService } from '@tutti/services';
import { PageSchema } from '@tutti/models';
import Basic from './basic';
import Components from './components';

export default {
  name: 'LandingPageEdit',

  components: { Basic, Components },

  data() {
    return {
      tab: 0,
      loading: false,
      created: false,
      page: new PageSchema(),
      components: [],
      componentsObject: {},
    };
  },

  async created() {
    this.tab = this.$route?.params?.tab || 0;

    await this.getPage();
    await this.getComponents();
    this.created = true;
  },

  methods: {
    async getPage() {
      if (this.$route.params.id) {
        const response = await PageSchemaService.getByIdAdmin(this.$route.params.id);

        if (response) {
          this.page = new PageSchema(response.data);
          this.page.setComponentsSchemaId();
          this.$setTitle(this.page.name);
        }
      }
    },

    async getComponents() {
      const response = await ComponentSchemaService.getAll({});

      if (response) {
        this.components = response.data.data;
        this.componentsObject = this.$reduce(this.components, '_id');
      }
    },

    async save(previous) {
      this.loading = true;
      const response = await PageSchemaService.save(this.page.get());
      if (response) {
        if (previous) {
          this.$previous();
        } else if (response.data._id) {
          this.$replace(this.$toEdit('page-schema', response.data._id));
        }
      }
      this.loading = false;
    },
  },
};
</script>
