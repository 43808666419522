var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-text', {
    staticClass: "mt-4"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "name": "name",
      "label": "Name",
      "required": ""
    },
    on: {
      "change": function change($event) {
        return _vm.page.setSlug(_vm.page.slug || _vm.page.name);
      }
    },
    model: {
      value: _vm.page.name,
      callback: function callback($$v) {
        _vm.$set(_vm.page, "name", $$v);
      },
      expression: "page.name"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "name": "slug",
      "label": "Slug",
      "required": "",
      "disabled": _vm.$route.params.id && !_vm.dev
    },
    on: {
      "change": function change($event) {
        return _vm.page.setSlug(_vm.page.slug);
      }
    },
    model: {
      value: _vm.page.slug,
      callback: function callback($$v) {
        _vm.$set(_vm.page, "slug", $$v);
      },
      expression: "page.slug"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "textarea",
      "name": "description",
      "label": "Description",
      "counter": "280",
      "max": "280"
    },
    model: {
      value: _vm.page.description,
      callback: function callback($$v) {
        _vm.$set(_vm.page, "description", $$v);
      },
      expression: "page.description"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "name": "path",
      "label": "Path",
      "required": ""
    },
    model: {
      value: _vm.page.path,
      callback: function callback($$v) {
        _vm.$set(_vm.page, "path", $$v);
      },
      expression: "page.path"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }