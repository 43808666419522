var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-draggable', {
    attrs: {
      "list": _vm.page.components,
      "handle": ".handle",
      "ghost-class": "ghost"
    }
  }, _vm._l(_vm.page.components, function (component, index) {
    var _vm$componentsObject$;

    return _c('v-card', {
      key: _vm.key + index,
      staticClass: "mb-4",
      attrs: {
        "outlined": ""
      }
    }, [_c('v-card-title', {
      staticClass: "py-2"
    }, [_c('vx-btn', {
      staticClass: "handle",
      attrs: {
        "icon": ""
      }
    }, [_c('vx-icon', [_vm._v("mdi-drag")])], 1), _vm._v(" #" + _vm._s(index + 1) + " "), _c('v-spacer'), _c('vx-btn', {
      attrs: {
        "icon": "",
        "disabled": index === 0
      },
      on: {
        "click": function click($event) {
          return _vm.up(index);
        }
      }
    }, [_c('vx-icon', [_vm._v("mdi-arrow-up")])], 1), _c('vx-btn', {
      attrs: {
        "icon": "",
        "disabled": index === _vm.page.components.length - 1
      },
      on: {
        "click": function click($event) {
          return _vm.down(index);
        }
      }
    }, [_c('vx-icon', [_vm._v("mdi-arrow-down")])], 1), _c('vx-btn', {
      attrs: {
        "icon": "",
        "disabled": _vm.page.components.length == 1
      },
      on: {
        "click": function click($event) {
          return _vm.removeField(index);
        }
      }
    }, [_c('vx-icon', [_vm._v("mdi-delete")])], 1)], 1), _c('v-card-text', {
      staticClass: "my-4"
    }, [_c('v-row', [_c('v-col', {
      staticClass: "py-0",
      attrs: {
        "cols": "12",
        "sm": "6"
      }
    }, [_c('vx-input', {
      attrs: {
        "type": "autocomplete",
        "items": _vm.components,
        "item-text": "name",
        "item-value": "_id",
        "name": "name",
        "label": "Component",
        "required": "",
        "append-outer-icon": "mdi-pencil"
      },
      on: {
        "click:append-outer": function clickAppendOuter($event) {
          return _vm.$edit('component-schema', component._schemaId);
        }
      },
      model: {
        value: component._schemaId,
        callback: function callback($$v) {
          _vm.$set(component, "_schemaId", $$v);
        },
        expression: "component._schemaId"
      }
    }), _c('vx-input', {
      attrs: {
        "type": "text",
        "name": "name",
        "label": "Name (override)"
      },
      model: {
        value: component.name,
        callback: function callback($$v) {
          _vm.$set(component, "name", $$v);
        },
        expression: "component.name"
      }
    }), _c('vx-input', {
      attrs: {
        "type": "text",
        "name": "class",
        "label": "Class (css)",
        "append-outer-icon": "mdi-information-outline"
      },
      on: {
        "click:append-outer": function clickAppendOuter($event) {
          return _vm.$openBlankWindow({
            href: 'https://vuetifyjs.com/en/styles/spacing'
          });
        }
      },
      model: {
        value: component.class,
        callback: function callback($$v) {
          _vm.$set(component, "class", $$v);
        },
        expression: "component.class"
      }
    }), _c('vx-input', {
      attrs: {
        "type": "checkbox",
        "name": "isActive",
        "label": "Enabled?"
      },
      model: {
        value: component.isActive,
        callback: function callback($$v) {
          _vm.$set(component, "isActive", $$v);
        },
        expression: "component.isActive"
      }
    })], 1), _c('v-col', {
      staticClass: "py-0",
      attrs: {
        "cols": "12",
        "sm": "6"
      }
    }, [_c('vx-img', {
      staticClass: "rounded border-primary-1",
      attrs: {
        "rectangle": "",
        "src": (_vm$componentsObject$ = _vm.componentsObject[component._schemaId]) === null || _vm$componentsObject$ === void 0 ? void 0 : _vm$componentsObject$.image
      }
    })], 1)], 1)], 1)], 1);
  }), 1), _c('v-card', [_c('vx-btn', {
    attrs: {
      "block": "",
      "x-large": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.dialog = true;
      }
    }
  }, [_vm._v("Add component")])], 1), _c('vx-dialog', {
    attrs: {
      "width": "1200"
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('template', {
    slot: "title"
  }, [_c('vx-input', {
    staticClass: "w-50",
    attrs: {
      "hide-details": "",
      "type": "text",
      "name": "name",
      "label": "Search component"
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c('v-row', [_vm._l(_vm.components, function (component, index) {
    return [_c('v-col', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: component.name.toLowerCase().includes((_vm.search || '').toLowerCase()),
        expression: "component.name.toLowerCase().includes((search || '').toLowerCase())"
      }],
      key: index,
      attrs: {
        "cols": "12",
        "sm": "4"
      }
    }, [_c('v-card', {
      staticClass: "h-100",
      attrs: {
        "outlined": ""
      },
      on: {
        "click": function click($event) {
          return _vm.addComponent(component);
        }
      }
    }, [_c('vx-img', {
      attrs: {
        "rectangle": "",
        "src": component.image
      }
    }), _c('v-divider'), _c('v-card-subtitle', [_vm._v(_vm._s(component.name))])], 1)], 1)];
  })], 2)], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }